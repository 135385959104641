interface Props {
  startText?: string;
  endText?: string;
}

export const TrustpilotWidgetSmall = ({
  startText = 'Fremragende',
  endText = '674 anmeldelser'
}: Props) => (
  <div
    className="flex justify-center items-center space-x-3 cursor-default text-gray-700 text-sm"
    title="4.8 stjerner på Trustpilot"
  >
    <span className="text-right w-32">{startText}</span>
    <TrustpilotStars />
    <span className="text-left w-32">{endText}</span>
  </div>
);

const TrustpilotStars = () => (
  <svg
    className="h-4"
    viewBox="0 0 512 96"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none">
      <g>
        <rect fill="#00B67A" x="0" y="0" width="96" height="96"></rect>
        <rect fill="#31b689" x="104" y="0" width="96" height="96"></rect>
        <rect fill="#00B67A" x="208" y="0" width="96" height="96"></rect>
        <rect fill="#00B67A" x="312" y="0" width="96" height="96"></rect>
        <rect fill="#00B67A" x="416" y="0" width="96" height="96"></rect>
        <path
          d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
);
