import { HomeHero } from '@/components/Home/HomeHero';
import {
  HomeSection,
  HomeSectionImage,
  HomeSectionSubTitle,
  HomeSectionText,
  HomeSectionTitle
} from '@/components/Home/HomeSection';
import { HomeSignUp } from '@/components/Home/HomeSignUp';
import Layout from '@/components/Layout/Layout';
import { PageMeta } from '@/components/PageMeta/PageMeta';
import { HelpScoutScript } from '@/components/Scripts/HelpScoutScript';
import { ALL_RECIPES_URL, API_ROOT_URL } from 'constants/url-constants';
import { getCdnImageUrl } from 'helpers';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { BasePage } from 'types';

interface PageProps extends BasePage {
  HeroVideoUrl: string;
  HeroTrustpilotStartText: string;
  HeroTrustpilotEndText: string;
  HeroText: string;
  HeroTitle: string;

  FirstPointImage: string;
  FirstPointSplash: string;
  FirstPointText: string;
  FirstPointTitle: string;

  SecondPointImage: string;
  SecondPointText: string;
  SecondPointTitle: string;

  ThirdPointImage: string;
  ThirdPointText: string;
  ThirdPointTitle: string;
}

export default function Home({ page }: { page: PageProps }) {
  // Redirect to the recipes page if the user is logged in
  const user = useSelector((state: RootState) => state.user);
  const router = useRouter();
  useEffect(() => {
    if (user.IsLoggedIn && typeof window !== 'undefined') {
      router.push(ALL_RECIPES_URL);
    }
  }, [user, router]);

  return (
    <>
      <Head>
        <title>{page.PageTitle}</title>
        <PageMeta page={page} />
      </Head>

      <Layout>
        <HomeHero
          videoUrl={page.HeroVideoUrl}
          title={page.HeroTitle}
          text={page.HeroText}
          trustpilotStartText={page.HeroTrustpilotStartText}
          trustpilotEndText={page.HeroTrustpilotEndText}
        />
      </Layout>

      <hr className="invisible my-5 md:my-8 lg:my-14" />

      <div className="overflow-hidden lg:container mx-auto">
        <FirstPoint
          image={page.FirstPointImage}
          splash={page.FirstPointSplash}
          title={page.FirstPointTitle}
          text={page.FirstPointText}
        />
        <SecondPoint
          image={page.SecondPointImage}
          title={page.SecondPointTitle}
          text={page.SecondPointText}
        />
        <ThirdPoint
          image={page.ThirdPointImage}
          title={page.ThirdPointTitle}
          text={page.ThirdPointText}
        />
      </div>

      <HomeSignUp />

      <HelpScoutScript />
    </>
  );
}

const FirstPoint = ({
  splash,
  image,
  title,
  text
}: {
  splash: string;
  image: string;
  title: string;
  text: string;
}) => (
  <HomeSection animateInFrom="left">
    <HomeSectionImage>
      <Image
        className="rounded-xl"
        src={getCdnImageUrl(image, 'w_1000')}
        alt="Klub Vollmer"
        layout="fill"
        objectFit="cover"
        objectPosition="30% 20%"
        unoptimized
      />

      {splash && (
        <div
          className={`
          bg-black/80
          backdrop-blur-md
          backdrop-opacity-20
          rotate-12
          text-sm
          text-white
          leading-5
          flex
          items-center
          px-4
          text-center
          rounded-full
          h-32
          w-32
          absolute
          right-2
          md:right-10
          top-2
          md:top-10
          z-10`}
          dangerouslySetInnerHTML={{ __html: splash }}
        ></div>
      )}
    </HomeSectionImage>

    <HomeSectionText>
      <HomeSectionTitle>{title}</HomeSectionTitle>
      <HomeSectionSubTitle>{text}</HomeSectionSubTitle>
    </HomeSectionText>
  </HomeSection>
);

const SecondPoint = ({
  image,
  title,
  text
}: {
  image: string;
  title: string;
  text: string;
}) => (
  <HomeSection animateInFrom="right" className="flex-col-reverse">
    <HomeSectionText>
      <HomeSectionTitle>{title}</HomeSectionTitle>
      <HomeSectionSubTitle>{text}</HomeSectionSubTitle>
    </HomeSectionText>

    <HomeSectionImage>
      <Image
        className="rounded-xl object-[0_30%] md:object-center"
        src={getCdnImageUrl(image, 'w_1000')}
        alt="Klub Vollmer"
        layout="fill"
        objectFit="cover"
        unoptimized
      />
    </HomeSectionImage>
  </HomeSection>
);

const ThirdPoint = ({
  image,
  title,
  text
}: {
  image: string;
  title: string;
  text: string;
}) => (
  <HomeSection animateInFrom="left">
    <HomeSectionImage>
      <Image
        className="rounded-xl"
        src={getCdnImageUrl(image, 'w_1000')}
        alt="Klub Vollmer"
        layout="fill"
        objectFit="cover"
        objectPosition="20% 20%"
        unoptimized
      />
    </HomeSectionImage>

    <HomeSectionText>
      <HomeSectionTitle>{title}</HomeSectionTitle>
      <HomeSectionSubTitle>{text}</HomeSectionSubTitle>
    </HomeSectionText>
  </HomeSection>
);

export async function getStaticProps() {
  const res = await fetch(API_ROOT_URL + '/PageV2/GetHomepage');
  const page = await res.json();
  return {
    props: {
      page
    },
    revalidate: 600 // In seconds,
  };
}
