module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      colors: {
        primary: '#0a4056',
        secondary: '#c2424e'
      },
      fontFamily: {
        display: ['Veneer', 'sans-serif']
      }
    }
  },
  plugins: []
};
