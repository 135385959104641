import { Button } from '@/components/Button';
import { TrustpilotWidgetSmall } from '../Trustpilot/TrustpilotWidgetSmall';

interface Props {
  videoUrl: string;
  trustpilotStartText: string;
  trustpilotEndText: string;
  text: string;
  title: string;
}

export const HomeHero = ({
  videoUrl,
  trustpilotStartText,
  trustpilotEndText,
  title,
  text
}: Props) => (
  <div className="text-center">
    <div className="relative pt-[56.25%] lg:mt-10 mb-6 overflow-hidden">
      <iframe
        className="absolute inset-0 w-full h-full lg:rounded-xl"
        src={videoUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        data-cookieconsent="ignore"
      ></iframe>
    </div>

    <div className="mb-6">
      <TrustpilotWidgetSmall
        startText={trustpilotStartText}
        endText={trustpilotEndText}
      />
    </div>

    <div className="md:container md:mx-auto">
      <h1
        className="font-bold text-3xl md:text-4xl px-6 mb-6 lg:px-0"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h1>

      <p
        className="font-semibold text-lg md:text-2xl px-6 lg:px-0 mb-6 md:mb-8"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>

    <ButtonScrollOn />
  </div>
);

const ButtonScrollOn = () => (
  <Button
    className="animate-bounce !h-14 !py-0 !px-2"
    onClick={() => {
      document.querySelector(`.talkpoint`).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }}
  >
    <svg
      className="w-6 h-6 text-white"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
    </svg>
  </Button>
);
