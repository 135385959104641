import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const sectionVariants = {
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: { duration: 1, delayChilden: 0.2, staggerChildren: 0.1 }
  },
  hidden: {
    x: 0,
    scale: 1.2,
    opacity: 0,
    transition: { duration: 1, delayChilden: 0.2, staggerChildren: 0.1 }
  }
};

export const HomeSection = ({ animateInFrom, className = '', children }) => {
  const fullConfig = resolveConfig(tailwindConfig);
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1000;
  const isSmallScreen = windowWidth < parseInt(fullConfig.theme.screens['lg']);

  const animation = useAnimation();
  const [ref, inView] = useInView({
    threshold: isSmallScreen ? 0 : 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    animation.start(inView ? 'visible' : 'hidden');
  }, [animation, inView]);

  const variants = {
    visible: {
      ...sectionVariants.visible
    },
    hidden: {
      ...sectionVariants.hidden,
      x: animateInFrom === 'left' ? -150 : 150
    }
  };

  return (
    <motion.div
      ref={ref}
      animate={animation}
      initial="hidden"
      variants={variants}
    >
      <div
        className={`
          lg:h-[60vh] 
          xl:h-[75vh] 
          overflow-hidden
          mb-3 
          md:mb-8
          flex 
          flex-col
          md:flex-row
          lg:space-x-10
          talkpoint
          ${className}`}
      >
        {children}
      </div>
    </motion.div>
  );
};

export const HomeSectionImage = ({ children }) => (
  <div
    className={`
        h-[250px]
        md:h-[400px]
        lg:h-auto
        mx-5
        mb-5
        relative
        md:mb-0
        md:w-1/2`}
  >
    {children}
  </div>
);

export const HomeSectionText = ({ children }) => (
  <div
    className={`
      self-center
      text-center
      md:w-1/2
      px-10
      md:px-14
      lg:px-20`}
  >
    {children}
  </div>
);

export const HomeSectionTitle = ({ children }) => (
  <h2
    className={`
      font-bold
      text-2xl
      mb-2
      lg:mb-4
      lg:text-2xl`}
    dangerouslySetInnerHTML={{ __html: children }}
  ></h2>
);

export const HomeSectionSubTitle = ({ children }) => (
  <h3
    className={`
      text-gray-800
      text-lg
      lg:text-xl
      lg:leading-relaxed
      lg:font-light
      xl:text-3xl
      xl:leading-relaxed
      mb-6
      md:mb-0`}
    dangerouslySetInnerHTML={{ __html: children }}
  ></h3>
);
