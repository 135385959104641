import Layout from '../Layout/Layout';
import PurchaseMembership from '../Membership/PurchaseMembership';

export const HomeSignUp = () => (
  // Using padding to ensure the text is not covered by the navbar
  // when navigating here via an anchor link
  <div id="signup" className="pt-20 -mt-10 md:pt-20 md:mt-0">
    <Layout>
      <div className="mb-20">
        <PurchaseMembership />
      </div>
    </Layout>
  </div>
);
